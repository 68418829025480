<template>
<div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8">
        <ListMenuBar
          search-placeholder-label="Search user by name, NPWP"
          @search="(val) => search(val)"
          @search-reset="() => reset()"
          @filter-changed="(val) => filterChanged(val)"
          style-dropdown-content="min-width:210px"
          :filter="true"
          :filter-data="filter"
          main-context-button-label="Add User"
          :main-context-button-target="{
            name: 'route.dashboard.user.editor.create'
          }"
          use-main-context-button
        ></ListMenuBar>
        <div class="px-12 py-12 my-8 bg-white rounded">
          <ListTableSimple @row-selected="showDetail" :ready="INTERNAL_USER_READY" :items="GET_INTERNAL_USERS" :columns="columns"></ListTableSimple>
          <SimplePagination v-show="INTERNAL_USER_READY" :min="1" :max="GET_INTERNAL_USER_TOTAL_PAGE" :page="fetchParam.currentPage" @move="(step) => movePage(step)" />
        </div>
      </div>
    </div>
    <TheSideCalendarBar :range="range" hide-today @date-updated="(val) => dateRangeUpdated(val)" />
  </div>
</template>

<script>
import ListMenuBar from '@/components/List/ListMenuBar.vue'
import ListTableSimple from '@/components/List/ListTableSimple.vue'
import TheSideCalendarBar from '@/components/TheSideCalendarBar.vue'
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import SimplePagination from '@/components/Paginate/SimplePagination.vue'
import { createNamespacedHelpers } from 'vuex'
const internalUser = createNamespacedHelpers('internalUser')

export default {
  components: {
    ListMenuBar: ListMenuBar,
    ListTableSimple: ListTableSimple,
    TheSideNavigationBar,
    TheSideCalendarBar,
    TheContentTopNavigationBar,
    SimplePagination
  },
  computed: {
    ...internalUser.mapGetters(['GET_INTERNAL_USERS', 'INTERNAL_USER_READY', 'GET_INTERNAL_USER_TOTAL_PAGE'])
  },
  data () {
    return {
      columns: [
        {
          label: 'Profile',
          name: 'profile',
          hasEmbeddedImage: true
        },
        {
          label: 'Name',
          name: 'fullname'
        },
        {
          label: 'Role',
          name: 'role'
        },
        {
          label: 'Join Date',
          name: 'created_at',
          isDate: true
        }
      ],
      items: [],
      range: {
        start: '',
        end: ''
      },
      fetchParam: {
        filter: {
          target: 'created_at',
          sort: 'asc'
        },
        currentPage: 1,
        range: {
          start: '',
          end: ''
        }
      },
      filter: [
        { label: 'Create date', target: 'created_at', sort: 'asc', checked: true },
        { label: 'Create date', target: 'created_at', sort: 'desc', checked: false },
        { label: 'Name', target: 'fullname', sort: 'asc', checked: false },
        { label: 'Name', target: 'fullname', sort: 'desc', checked: false }
      ]
    }
  },
  mounted () {
    // var token = localStorage.getItem('access_token')
    // if (token === null) {
    //   this.$router.push({ name: 'route.root' })
    // } else {
    //   console.log(token)
    // }
  },
  created () {
    this.range.start = this.moment().startOf('month').format('YYYY-MM-DD')
    this.range.end = this.moment().endOf('month').format('YYYY-MM-DD')
    this.fetchParam.start = this.range.start
    this.fetchParam.end = this.range.end
    this.reset()
  },
  methods: {
    ...internalUser.mapActions(['getUsers', 'findUsers']),
    dateRangeUpdated (val) {
      this.fetchParam.range.start = this.moment(val.start).format('YYYY-MM-DD')
      this.fetchParam.range.end = this.moment(val.end).format('YYYY-MM-DD')
      this.reset()
    },
    filterChanged (val) {
      this.fetchParam.filter = val
      this.reset()
    },
    showDetail (index) {
      this.$router.push({ name: 'route.dashboard.user.editor.detail', params: { id: this.GET_INTERNAL_USERS[index].id } })
    },
    search (val) {
      var data = { find: val }
      this.findUsers({ data: data })
    },
    movePage (step) {
      this.fetchParam.currentPage += step
      // console.log(this.fetchParam.currentPage)
      this.reset()
    },
    reset () {
      this.getUsers({ data: { range: this.fetchParam.range, page: this.fetchParam.currentPage, filter: this.fetchParam.filter } })
        .catch((err) => {
          if (err.response.status !== undefined && err.response.status === 401) {
            localStorage.removeItem('access_token')
            this.$router.push({ name: 'route.root' })
          } else if (err.response !== undefined && err.response.status === 403) {
            this.$router.push({ name: 'route.dashboard.home' })
          }
        })
    }
  }
}
</script>
