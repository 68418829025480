<template>
<div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8 mt-10">
        <div class="block pb-2"><strong>Registered Tags</strong></div>
        <div class="block my-2"><hr></div>
        <div class="block pb-2 my-8">
          <strong>Add new tag:</strong>
          <div class="inline-block w-50 ml-10">
            <input-text-box
            type="text"
            placeholder="Enter tag name"
            @input="val => processValue(val)"
            hide-errors
            />
          </div>
          <div class="inline-flex">
            <ButtonSolidBox class="px-12 ml-10" @click="saveNewTag()">
              Save
            </ButtonSolidBox>
          </div>
        </div>
        <div class="block pb-2 mx-3"><Loading :show="!TAG_READY" /></div>
        <div class="inline-flex my-4 mx-3 border border-gray-500 rounded-full py-2 px-6 shadow " v-for="data in GET_TAGS" :key="'tag'+data.id">
            <strong>{{ data.tag_name }}</strong>
            <button type="button" class="my-auto rounded-full border ml-4 bg-red p-1" @click="deleteTag(data.id)">
              <svg width="18" height="18" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.7081 24.2925C25.801 24.3854 25.8747 24.4957 25.9249 24.6171C25.9752 24.7385 26.0011 24.8686 26.0011 25C26.0011 25.1314 25.9752 25.2615 25.9249 25.3829C25.8747 25.5043 25.801 25.6146 25.7081 25.7075C25.6151 25.8004 25.5048 25.8741 25.3835 25.9244C25.2621 25.9747 25.132 26.0006 25.0006 26.0006C24.8692 26.0006 24.7391 25.9747 24.6177 25.9244C24.4963 25.8741 24.386 25.8004 24.2931 25.7075L16.0006 17.4137L7.70806 25.7075C7.52042 25.8951 7.26592 26.0006 7.00056 26.0006C6.73519 26.0006 6.4807 25.8951 6.29306 25.7075C6.10542 25.5199 6 25.2654 6 25C6 24.7346 6.10542 24.4801 6.29306 24.2925L14.5868 16L6.29306 7.7075C6.10542 7.51986 6 7.26536 6 7C6 6.73464 6.10542 6.48014 6.29306 6.2925C6.4807 6.10486 6.73519 5.99944 7.00056 5.99944C7.26592 5.99944 7.52042 6.10486 7.70806 6.2925L16.0006 14.5863L24.2931 6.2925C24.4807 6.10486 24.7352 5.99944 25.0006 5.99944C25.2659 5.99944 25.5204 6.10486 25.7081 6.2925C25.8957 6.48014 26.0011 6.73464 26.0011 7C26.0011 7.26536 25.8957 7.51986 25.7081 7.7075L17.4143 16L25.7081 24.2925Z" fill="#FFFFFF"/>
              </svg>
            </button>
        </div>
        <div class="inline-flex my-4 mx-3 border border-gray-500 rounded-full py-2 px-6 shadow" v-show="GET_TAGS.length < 1 && TAG_READY">
          <span>No tag found, please add one or more tag.</span>
        </div>
        <div class="block pb-2 mt-10"><hr></div>
        <div class="block pb-2 mt-10"><strong>Removed Tags</strong></div>
        <div class="block pb-2 mx-3"><Loading :show="!TAG_READY" /></div>
        <div class="inline-flex my-4 mx-3 border border-gray-500 rounded-full py-2 px-6 shadow bg-red-200" v-for="data in GET_DELETED_TAGS" :key="'removed'+data.id">
            <strong>{{ data.tag_name }}</strong>
            <button type="button" class="my-auto rounded-full border ml-4 bg-red p-1" @click="restoreTags(data.id)">
              <svg width="18" height="18" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.7076 14.7083C25.6147 14.8013 25.5044 14.875 25.383 14.9253C25.2616 14.9757 25.1315 15.0016 25.0001 15.0016C24.8687 15.0016 24.7385 14.9757 24.6171 14.9253C24.4957 14.875 24.3854 14.8013 24.2926 14.7083L17.0001 7.41454V27.0008C17.0001 27.266 16.8947 27.5204 16.7072 27.7079C16.5196 27.8954 16.2653 28.0008 16.0001 28.0008C15.7349 28.0008 15.4805 27.8954 15.293 27.7079C15.1054 27.5204 15.0001 27.266 15.0001 27.0008V7.41454L7.70757 14.7083C7.51993 14.8959 7.26543 15.0013 7.00007 15.0013C6.7347 15.0013 6.48021 14.8959 6.29257 14.7083C6.10493 14.5206 5.99951 14.2662 5.99951 14.0008C5.99951 13.7354 6.10493 13.4809 6.29257 13.2933L15.2926 4.29329C15.3854 4.20031 15.4957 4.12655 15.6171 4.07623C15.7385 4.0259 15.8687 4 16.0001 4C16.1315 4 16.2616 4.0259 16.383 4.07623C16.5044 4.12655 16.6147 4.20031 16.7076 4.29329L25.7076 13.2933C25.8005 13.3862 25.8743 13.4964 25.9246 13.6178C25.975 13.7392 26.0009 13.8694 26.0009 14.0008C26.0009 14.1322 25.975 14.2623 25.9246 14.3837C25.8743 14.5051 25.8005 14.6154 25.7076 14.7083Z" fill="#FFFFFF"/>
              </svg>
            </button>
        </div>
        <div class="inline-flex my-4 mx-3 border border-gray-500 rounded-full py-2 px-6 shadow" v-show="GET_DELETED_TAGS.length < 1 && TAG_READY">
          <span>No deleted tag found.</span>
        </div>
        <!-- <ListMenuBar
          search-placeholder-label="Search user by tag name"
          @search="(val) => search(val)"
          @search-reset="() => reset()"
          @filter-changed="(val) => filterChanged(val)"
          style-dropdown-content="min-width:210px"
          :filter="true"
          :filter-data="filter"
          main-context-button-label="Add Category"
          :main-context-button-target="{
            name: 'route.dashboard.category.editor.create'
          }"
          use-main-context-button
        ></ListMenuBar> -->
        <!-- <div class="px-12 py-12 my-8 bg-white rounded">
          <ListTableSimple @row-selected="showDetail" :ready="TAG_READY" :items="GET_TAGS" :columns="columns"></ListTableSimple>
        <SimplePagination v-show="TAG_READY" :min="1" :max="" :page="fetchParam.currentPage" @move="(step) => movePage(step)" />
        </div> -->
      </div>
    </div>
    <!-- <TheSideCalendarBar :range="range" hide-today @date-updated="(val) => dateRangeUpdated(val)" /> -->
  </div>
</template>

<script>
// import ListMenuBar from '@/components/List/ListMenuBar.vue'
// import ListTableSimple from '@/components/List/ListTableSimple.vue'
// import SimplePagination from '@/components/Paginate/SimplePagination.vue'
// import TheSideCalendarBar from '@/components/TheSideCalendarBar.vue'
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import Loading from '@/components/Loading.vue'
import { createNamespacedHelpers } from 'vuex'

const relatedTag = createNamespacedHelpers('relatedTag')

export default {
  components: {
    InputTextBox,
    TheSideNavigationBar,
    ButtonSolidBox,
    TheContentTopNavigationBar,
    Loading
    // ListMenuBar: ListMenuBar,
    // ListTableSimple: ListTableSimple,
    // SimplePagination
  },
  computed: {
    ...relatedTag.mapGetters(['GET_TAGS', 'TAG_READY', 'GET_DELETED_TAGS'])
  },
  data () {
    return {
      newTag: ''
    }
  },
  mounted () {
    // var token = localStorage.getItem('access_token')
    // if (token === null) {
    //   this.$router.push({ name: 'route.root' })
    // } else {
    //   console.log(token)
    // }
  },
  created () {
    // this.range.start = this.moment().startOf('month').format('YYYY-MM-DD')
    // this.range.end = this.moment().endOf('month').format('YYYY-MM-DD')
    // this.fetchParam.start = this.range.start
    // this.fetchParam.end = this.range.end
    // this.reset()
    this.getTags()
  },
  methods: {
    ...relatedTag.mapActions(['getAllTags', 'storeNewTag', 'removeTag', 'getAllDeletedTags', 'restoreTag']),
    saveNewTag () {
      if (this.newTag !== '') {
        this.storeNewTag({ tagName: this.newTag }).then((_) => this.getTags())
      }
    },
    deleteTag (tagId) {
      this.removeTag({ id: tagId }).then((_) => this.getTags())
    },
    processValue (val) {
      this.newTag = val
    },
    getTags () {
      this.getAllTags()
      this.getAllDeletedTags()
    },
    restoreTags (tagId) {
      this.restoreTag({ id: tagId }).then((_) => this.getTags())
    }
  }
}
</script>
